@import "../../shared/styles/base";

.tab__component {
  background: #eeeeee;
  min-height: 100%;
  min-width: 100%;
  // padding: 15px;
  border-radius: 5px;

  .tab_tabs {
    &_tab {
      background: #fff;
    }
  }

  .tab__tabs > div > button > div > div {
    // font-family: 'AvenirLTStd-Heavy';
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    text-transform: none;
  }
}
