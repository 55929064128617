@import "../../styles/base";

.input-field-container {
  align-items: flex-start;
  appearance: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 25px;
  padding-right: 30px;
  position: relative;
  text-indent: 1px;
  text-overflow: "";
  width: 220px;

  @media #{$mobile} {
    padding: 0 12.5px;
    width: "calc(50% - 25px - #{2 * $input-padding})";
  }

  .input-field-label {
    @include avenir;
    color: $dibs-darker-grey;
    font-size: 14px;
    max-width: 100%;
    padding-bottom: 12px;
    width: 100%;
  }

  .add-space-to-right {
    padding-right: 5px;
  }

  .currency-symbol {
    color: $input-grey;
    font-size: 14px;
    left: 3px;
    position: absolute;
    top: 32px;
  }

  input,
  span {
    max-width: 100%;
    width: 220px - (2 * $input-padding);

    &.invalid {
      border-color: $dibs-error-red;
    }

    &.valid {
      color: $black;
    }

    &.with-currency {
      margin-left: 12px;
    }
  }
}

.input-field-wider {
  @include avenir;
  width: 300px !important;
}

@media #{$desktop} {
  .expiry {
    width: 100px;
  }

  .cvc {
    width: 60px;
  }
}

.roster-table__tool-tip {
  border: 1px solid $dibs-light-grey !important;
  background-color: $white !important;
  color: $black !important;
  max-width: 250px;
  overflow-wrap: break-word;
  text-align: left;
  box-shadow: 1px 1px 5px fade-out($black, 0.995);
}

.__react_component_tooltip {
  &::after {
    border: none !important;
  }
}
