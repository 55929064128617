@import "../../../../shared/styles/base";

.client-search-bar-container {
  height: 100%;
  margin-bottom: 5px;

  .client-search-bar-subheading {
    @include avenir;
    color: $input-grey;
    font-size: 14px;
    margin: 7px 0 35px;
    width: 100%;
  }
}
