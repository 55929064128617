@import "../../../shared/styles/base";

.edit-class-wrapper {
  margin: 40px;

  .input-field-container {
    @media #{$mobile} {
      padding: 0px !important;
      width: auto !important;
    }
  }

  .back-button {
    @include hide-on-desktop;
    margin-left: 15px;
  }
}

.edit-class__back-button {
  align-items: center;
  color: #4a4a4a;
  display: flex;
}

.checkbox__container {
  display: flex;

  p {
    margin: 0px;
    margin-left: 10px;
    margin-bottom: 5px;
  }

  .checkbox {
    border: 1px solid $rose-main;
    border-radius: 2px;
    height: 15px;
    width: 15px;
  }
}

.checkbox__container__no_packages {
  display: flex;

  p {
    margin: 0px;
    margin-left: 10px;
    margin-bottom: 33px;
  }

  .checkbox {
    border: 1px solid $rose-main;
    border-radius: 2px;
    height: 15px;
    width: 15px;
  }
}

.select-field-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 25px;
  padding-right: 30px;
  position: relative;
  text-indent: 1px;
  width: 220px;

  .select-instructor {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 20px;
    padding-right: 30px;
    position: relative;
    text-indent: 1px;
    width: 220px;

    @media #{$mobile} {
      padding-left: 0px;
    }
  }
}

.important-note {
  margin-bottom: 25px;
  color: #e09028;
  font-weight: bold;
  line-height: 25px;
}

.roster-table__tool-tip {
  border: 1px solid $dibs-light-grey !important;
  background-color: #f2f2f2 !important;
  color: $black !important;
  max-width: 250px;
  overflow-wrap: break-word;
  text-align: left;
  box-shadow: 1px 1px 5px fade-out($black, 0.995);
}

.__react_component_tooltip {
  &::after {
    border: none !important;
  }
}
