@import "./base";

.modal {
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  &.open {
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    z-index: 1;
  }
}

.modal__content {
  background-color: $white;
  border: 1px solid $dibs-dark-grey;
  border-radius: 5px;
  margin: 15% auto;
  width: 360px;
}
.modal__header {
  @include avenir-heavy();
  border-bottom: 2px solid $dibs-light-grey;
  box-sizing: border-box;
  font-size: 14px;
  padding: 20px;
  width: 100%;
}

.modal__body {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 20px;
  width: 330px;
}

.modal__divider {
  border-bottom: 2px solid $dibs-light-grey;
}

.modal__buttons {
  height: 50px;
}

.modal__close,
.modal__submit {
  @include avenir-heavy();
  float: right;
  margin-right: 20px;
  margin-top: 18px;
}

.not-submittable {
  @include avenir-heavy();
  color: $dibs-grey;
  float: right;
  margin-right: 20px;
  margin-top: 18px;
  pointer-events: none;
}

.modal__checkbox-option {
  align-items: center;
  display: flex;
  justify-content: flex-start;

  button {
    display: inline;
  }
}
