@import "../../styles/base";

// Position and sizing of burger button
.bm-burger-button {
  display: none;
}

// Color/shape of burger icon bars
.bm-burger-bars {
  background: $burger-menu-background;
  height: 15% !important;
}

// Position and sizing of clickable cross button
.bm-cross-button {
  height: 60px !important;
  width: 60px !important;

  button {
    outline: none;
  }

  img {
    background: none;
  }
}

// Color/shape of close button cross
.bm-cross {
  background: $burger-menu-background;
  width: 37px !important;
}

// General sidebar styles
.bm-menu-wrap {
  @include hide-on-desktop;
  top: 0 !important;
  z-index: 50000 !important;

  a,
  button {
    @include avenir-roman;
    color: $white;
    font-size: 14px;
    margin-bottom: 15px;
  }
}

.bm-menu {
  background: $burger-menu-background;
  font-size: 1.15em;
  padding: 2.5em 1.5em 0;
}

// Morph shape necessary with bubble or elastic
.bm-morph-shape {
  fill: $burger-menu-background;
}

.bm-icon {
  background: $website-dibs-blue;

  path {
    fill: #fff;
  }
}

// Styling of overlay
.bm-overlay {
  background: fade-out($black, 0.7);
  top: 0 !important;
  z-index: 4000 !important;
}

.burger-menu-icon {
  @include hide-on-desktop;
  position: fixed;
  left: 10px;
  top: 13px;
  z-index: 5000;

  svg {
    width: 40px;
  }
}
