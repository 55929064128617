@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes dibs-loader-animation {
  50% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
  }
}

@keyframes top-bar-height-in {
  @for $i from 0 through 20 {
    #{percentage($i / 20)} {
      transform: translateY((-118 * (20 - $i) / 20) + px);
      @media (max-width: "767px") {
        transform: translateY((-110 * (20 - $i) / 20) + px);
      }
    }
  }
}

@keyframes top-bar-height-out {
  @for $i from 0 through 20 {
    #{percentage(1 - ($i / 20))} {
      transform: translateY((-118 * (20 - $i) / 20) + px);
      @media (max-width: "767px") {
        transform: translateY((-110 * (20 - $i) / 20) + px);
      }
    }
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(30px);
  }

  60% {
    transform: translateY(15px);
  }
}

@keyframes bounce-mobile {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(10px);
  }

  60% {
    transform: translateY(5px);
  }
}

@keyframes zoomIntoBackground {
  @for $i from 0 through 10 {
    $k: (10 - $i) / 10;
    #{percentage($i / 10)} {
      transform: scale(#{$k});
      @if $i < 9 {
        visibility: visible;
        position: static;
      }
      @if $i > 8 {
        visibility: hidden;
        position: absolute;
      }
    }
  }
}

@keyframes rollDown {
  @for $i from 0 through 10 {
    #{percentage($i / 10)} {
      // height: #{$i} * 10;
      height: calc($i * 10);
    }
  }
}

@keyframes slide-left {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translatex(0%);
  }
}

@keyframes rising-fade-in {
  0% {
    opacity: 0;
    transform: translate(0, 5px);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}
