@import "../../shared/styles/base";

.top-bar-container {
  align-items: center;
  background-color: $white;
  border-bottom: solid 1px $dibs-grey;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.11);
  display: flex;
  height: $studio-admin-top-bar-height;
  justify-content: center;
  position: relative;
  z-index: 20;

  @media #{$desktop} {
    display: none;
  }

  .top-bar-heading {
    @include avenir-roman;
    font-size: 16px;
    font-weight: 500;
  }
}
