// Variables
// Please keep in (approx) alphabetical order, there's a lot to keep track of
$alt-light-grey: #f5f5f5;
$black: #4a4a4a;
$black-link-hover-color: #c0c0c0;
$burger-menu-background: #373a47;
$button-orange: #fd8f25;
$buy-button-hover: rgba(155, 155, 155, 1);
$calendar-highlight: rgba(68, 138, 255, 0.48);
$muted-calendar-highlight: rgba(68, 137, 255, 0.295);
$cart-border-color: rgb(205, 205, 205);
$cart-number-background: #ff1744;
$dashboard-grey: #cfd8dc;
$dashboard-dark-grey: #4f4f4f;
$dashboard-red: #f50;
$dashboard-tile-desktop-width: 190px;
$dashboard-yellow: #ffdd67;
$muted-yellow: #f5e9bf;
$decrease-red: rgb(226, 104, 90);
$dibs-admin-light-grey: #ccc;
$dibs-alt-light-blue: #eaf0ff;
$dibs-alt-grey: #d4d4d4;
$dibs-blue: #448aff;
$dibs-box-shadow-color: rgba(0, 0, 0, 0.15);
$dibs-button-height: 35px;
$dibs-dark-blue: #226ae3;
$dibs-dark-grey: #616161;
$dibs-darker-grey: #414141;
$dibs-error-red: #f50;
$dibs-success-green: #00c372;
$dibs-grey: #cdcdcd;
$dibs-highlight-color: #e5d400;
$dibs-hover-blue: #79b0ff;
$dibs-light-blue: #dfe9ff;
$dibs-light-grey: #efefef;
$dibs-pink: #edc4b9;
$dibs-navigation-bar-blue: #6e9cff;
$dibs-orange: #ff8f00;
$dibs-hover-orange: lighten($dibs-orange, 5%);
$dibs-outline-blue: #82b1ff;
$dibs-overlay-color: rgba(15, 15, 15, 0.5);
$date-selector-height: 60px;
$dibs-standard-grey: #f9f9f9;
$dibs-z-index: 50000000;
$enable-flex: true !default;
$enable-grid-classes: true !default;
$error-bar-button-width: 30px;
$error-grey: rgba(0, 0, 0, 0.9);
$green-color: #00c372;
$success-green: #417505;
$grey: #999;
$increase-green: rgb(104, 232, 102);
$input-grey: #9b9b9b;
$input-padding: 3px;
$link-scroll-target-bottom-buffer: 110px;
$link-scroll-target-top-buffer: 110px;
$live-indicator-green: #00e789;
$none: none;
$offline-indicator-background: #e3f2fd;
$online-indicator-background: #e3f2fd;
$red: #d0021b;
$rose-gold: #e7b2a5;
$rose-main: #c96248;
$dibs-green: #55c797;
$confirmation-green: #afd1a6; //   a5c69c
$gold: #eebe3f;
$purple: #9c89dc;
$navy: #4963c9;
$dibs-teal: #8cd4ba;
$light-rose: #fff4f1;
$select-background-image: url("/images/select-background.png");
$sidebar-cart-border-color: rgba(0, 0, 0, 0.5);
$slider-handle-grey: #9e9e9e;
$sold-out-grey: #ddd;
$steel-blue: #5a6880;
$steel-grey: #b0bec5;
$steel-grey-plus: #bbd3df;
$studio-admin-dibs-button-height: 30px;
$studio-admin-navigation-bar-desktop-width: 10px;
$studio-admin-navigation-bar-mobile-height: 10px;
$studio-admin-top-bar-height: 47px;
$studio-box-shadow: rgba(155, 155, 155, 0.4);
$table-background-grey: #e5e5e5;
$table-bg-accent: #eee;
$table-outer-borders-grey: #dedede;
$table-borders-grey: #b8b8b8;
$table-text-grey: #393939;
$text-grey: #4e4e4e;
$table-expanded-row-bg: #fafafa;
$table-expanded-row-border: #eaeaea;
$table-expanded-row-label: #6d6d6d;
$time-and-date-selector-column-cutoff: 890px;
$top-bar-border-color: #979797;
$topbar-my-plans-red: #f9245f;
$topbar-inactive-tab-grey: rgba(209, 211, 213, 0.59);
$transaction-filter-box-shadow: rgba(0, 0, 0, 0.33);
$transaction-list-item-heading-grey: #f6f6f6;
$transaction-list-item-green: #88d041;
$transaction-table-column-sorted-shadow: rgba(0, 0, 0, 0.6);
$transparent: rgba(0, 0, 0, 0);
$website-dibs-blue: #4b85fe;
$website-dibs-button-height: 40px;
$website-dibs-hover-blue: lighten($website-dibs-blue, 5%);
$white: #fff;
$widget-topbar-desktop-height: 65px;
$widget-topbar-mobile-height: 80px;
$credit-balance-green: #00c853;
$expiration-date-orange: #ffa726;
$spot-grey: #d1d1d1;
$spot-background-grey: #eeeeee;

// Media query stuff
$mobile-breakpoint: 767px;
$tablet-breakpoint: 768px;

$desktopminwidth: "(min-width : 767px)";

$desktop: $desktopminwidth;
$mobile: "(max-width : 767px)";
// $mobile: (
//     max-width: $mobile-breakpoint,
// );
$tablet: (
  max-width: $tablet-breakpoint,
);

$mobile-device: (
  max-device-width: $mobile-breakpoint,
);
$grid-breakpoints: (
  xs: 0,
  sm: 476px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);
$widget-desktop-cutoff: 521px;
$widget-desktop: (
  min-width: $widget-desktop-cutoff,
);
$widget-mobile-cutoff: 520px;
$widget-mobile: (
  max-width: $widget-mobile-cutoff,
);
$iphone-x: only screen and
  (
    device-width: 375px,
  )
  and
  (
    device-height: 812px,
  )
  and (-webkit-device-pixel-ratio: 3);
$portrait: (
  orientation: portrait,
);
$landscape: (
  orientation: landscape,
);
