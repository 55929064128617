@import "./base";

.card-container {
  font-size: 12px;
  display: flex;
  padding: 5px;
  margin-bottom: 33px;

  .card-dec-2021 {
    align-items: center;
    background-color: $white;
    border: solid 1px $dibs-light-grey;
    box-shadow: 1px 2px 2px 0 $dibs-box-shadow-color;
    // display: flex;
    flex-direction: column;
    justify-content: center;
    width: 150px;
    text-align: center;
    position: relative;
    padding: 8px 10px 20px;

    > .card-button,
    .card-buttons {
      bottom: -21px;
      position: absolute;
    }

    > .card-button {
      left: 25px;
    }

    > .card-buttons {
      display: flex;
      flex-direction: row;
      flex: 1;
      width: 100%;
      left: 0;

      .card-button {
        position: relative;
        width: initial;
        flex: 1;
        margin: 0 12px;
      }
    }
  }

  .card {
    align-items: center;
    background-color: $white;
    border: solid 1px $dibs-light-grey;
    box-shadow: 1px 2px 2px 0 $dibs-box-shadow-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 150px;
    text-align: center;
    position: relative;
    padding: 8px 10px 20px;

    > .card-button,
    .card-buttons {
      bottom: -21px;
      position: absolute;
    }

    > .card-button {
      left: 25px;
    }

    > .card-buttons {
      display: flex;
      flex-direction: row;
      flex: 1;
      width: 100%;
      left: 0;

      .card-button {
        position: relative;
        width: initial;
        flex: 1;
        margin: 0 12px;
      }
    }
  }
  &.card-zoom-out {
    animation: zoomIntoBackground 0s linear forwards;
  }

  &.card-zoom-in {
    animation: zoomIntoBackground 0s linear reverse;
  }
}
