@import "../../shared/styles/base";

.add-promo-code-container {
  @media #{$desktop} {
    padding-bottom: 80px;
  }

  .dibs-submit-btn-container {
    @media #{$mobile} {
      margin-bottom: 60px;
    }
  }

  .promo-recurring-container {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 50px;
  }

  .select-wrapper {
    width: 200px;
    margin-bottom: 20px;
  }

  .input-field-label {
    margin-bottom: 5px;
  }

  .and-or-input-container {
    .input-field-container {
      padding-right: 5px;
      width: 200px - (2 * $input-padding);
      @media #{$mobile} {
        padding: 0 17.5px;
        width: unquote("calc(50% - 55px - #{2 * $input-padding})");
      }
    }

    .and-or {
      @include avenir-roman;
      align-self: center;
      color: $dibs-darker-grey;
      display: flex;
      font-size: 14px;
      font-weight: 900;
      height: 40px;
      justify-content: center;
      width: 60px;

      @media #{$mobile} {
        width: 40px;
      }
    }
  }
}
