.retail-section {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  .retail-section {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
  }
}
