.retail-edit-modal-section {
  form {
    margin-top: 0;

    .dibs-input-fields {
      flex: 1;
      display: flex;
      align-items: normal;
      flex-direction: column;

      .input-field-container {
        flex: 1;
        width: auto;

        input {
          width: 100%;
        }
      }
    }
  }
}
