@import "../../shared/styles/base";

.front-desk-container {
  display: flex;
  flex-direction: column;
  background: #fff;
  // padding-left: 15px;
  padding-top: 10px;

  &:not(.is-admin) {
    .create-client-account-container {
      border-bottom: none;
    }
  }
}

.important-note {
  margin-bottom: 25px;
  color: #e09028;
  font-weight: bold;
  line-height: 25px;
}
