@import "../../shared/styles/base";

.dibs-mobile-back-button {
  @include hide-on-desktop;
  align-items: center;
  display: flex;
  height: $studio-admin-top-bar-height;
  right: 20px;
  position: absolute;
  top: 0;
  z-index: 30;

  svg {
    height: 14px;
    width: 14px;

    path {
      fill: none;
      stroke: $black;
    }
  }
}

.mobile-profile-picture-button {
  @include hide-on-desktop;
  margin-left: 5px;
  z-index: 30;

  img {
    border-radius: 50%;
    height: 32px;
    width: 32px;
  }
}
