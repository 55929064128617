@import "../../shared/styles/base";

.tutorial-controller-modal {
  background: $white;
  border-radius: 5px;
  box-shadow: 0 3px 0 fade-out($black, 0.7);
  position: absolute;
  width: 250px;
  z-index: 2;

  &.visible {
    animation: fade-in linear 0.1s;
    opacity: 1;
  }

  &.hidden {
    opacity: 0;
    transition: opacity 0.1s;
  }

  &.first-step {
    margin-left: unquote(
      "calc(50% + #{$studio-admin-navigation-bar-desktop-width / 2} - 125px)"
    );
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    @media #{$mobile} {
      margin-left: unquote("calc(50% - 125px)");
    }
  }

  .left-tail,
  .bottom-tail,
  .top-tail {
    position: absolute;

    path {
      fill: $white;
      stroke: none;
    }

    .shadow {
      fill: fade-out($black, 0.7);
    }
  }

  .left-tail {
    height: 25px;
    left: -14px;
    top: 10px;
    width: 15px;
    z-index: -1;
  }

  .top-tail {
    height: 15px;
    left: 112.5px;
    top: -14px;
    width: 25px;
    z-index: 1;
  }

  .bottom-tail {
    bottom: -14px;
    height: 15px;
    left: 112.5px;
    width: 25px;
    z-index: 1;
  }

  .tutorial-controller-modal-heading {
    @include avenir-roman;
    align-items: flex-end;
    color: $dibs-dark-grey;
    display: flex;
    font-size: 14px;
    padding: 10px 12px;

    img {
      height: 25px;
      margin-left: 7px;
      width: 25px;
    }
  }

  .tutorial-controller-modal-content {
    @include avenir;
    border-bottom: solid 1px $dibs-alt-grey;
    color: $dibs-dark-grey;
    margin: 0 12px;
    padding-bottom: 12px;
  }

  .tutorial-controller-modal-button-container {
    display: flex;
    justify-content: flex-end;
    padding: 12px;
    width: unquote("calc(100% - 24px)");

    button {
      @include avenir-roman;
      color: $rose-gold;
      font-size: 14px;
      outline: none;

      &:hover {
        color: $dibs-hover-blue;
      }

      &:hover,
      &:active {
        outline: none;
      }
    }
  }
}
