@import "../../shared/styles/base";

.navigation-bar-container {
  @include hide-on-mobile;
  align-items: center;
  background: $white;
  color: $white;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: $studio-admin-navigation-bar-desktop-width;

  .navigation-bar-dibs-logo {
    @include hide-on-mobile;
    margin: 40px 0 20px;
    width: 100% * calc(1 / 3);
  }

  .navigation-bar-employee-name {
    @include hide-on-mobile;
    @include avenir;
    font-size: 14px;
    margin-top: 20px;
    text-align: center;
    color: $black;
  }

  .navigation-bar-studio-name {
    @include hide-on-mobile;
    @include avenir-roman;
    font-size: 14px;
    margin-top: 35px;
    text-align: center;
    width: 80%;
    color: $black;
  }

  .navigation-bar-nav-links-container {
    display: flex;
    flex-direction: column;
    margin-top: 45px;
    width: 100%;

    button {
      padding: 5px 0;
      text-align: left;
    }
  }

  .nav-link-label {
    @include avenir-roman;
    color: $black;
    display: inline;
    font-size: 14px;
  }

  .faq-link {
    padding: {
      bottom: 5px;
      left: 35px;
      top: 5px;
    }
  }
}
