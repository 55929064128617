@import "../../shared/styles/base";

// sass-lint:disable class-name-format, no-important

.select-field-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 25px;
  padding-right: 30px;
  width: 220px;

  // @media max-width: 767px;

  //  {
  //     padding: 0 12.5px;
  //     width: calc((50% - 25px) - calc(2 * $input-padding));
  // }
  @media only screen and (max-width: 767px) {
    padding: 0 12.5px;
    width: calc((50% - 25px) - calc(2 * $input-padding));
  }
}

.select-field-label {
  @include avenir;
  color: $dibs-darker-grey;
  font-size: 14px;
  max-width: 100%;
  padding-bottom: 12px;
  width: 100%;
}

.Select {
  box-shadow: 0 !important;
  margin: 2px 0 20px;
  width: 100%;

  .Select-control {
    background: none !important;
    border: 0;
    border-bottom: solid 1px $rose-gold;
    border-radius: 0;
    box-shadow: none !important;
    height: 22px;
    padding: 3px;

    .Select-placeholder {
      line-height: 18px;
    }

    .Select-value {
      line-height: 22px;
      padding: 0;

      .Select-value-label {
        color: $input-grey;
        line-height: 22px;
      }
    }

    .Select-input {
      height: 22px;
      padding: 0;
      margin-left: -3px;

      input {
        padding: 0;
      }
    }
  }

  .Select-arrow-zone {
    padding-top: 0;
  }

  .Select-arrow {
    border-top-color: $rose-gold !important;
  }

  .Select-option {
    font-size: 12px !important;
  }

  &.is-open {
    .Select-arrow {
      border-bottom-color: $rose-gold !important;
    }
  }

  &.is-focused,
  &.is-open {
    .Select-control {
      border-bottom: solid 2px $rose-gold;
    }
  }

  &.is-disabled {
    .Select-control {
      border: 0;
    }

    .Select-arrow {
      display: none;
    }
  }
}
