@import "../../shared/styles/base";

.banner-container {
  width: 100%;
  z-index: 100000000;

  @media #{$desktop} {
    position: absolute;
  }
}
