@import "../../shared/styles/base";

.tutorial-node-clone-container {
  background: $white;
  opacity: 0;
  padding: 3px;
  position: absolute;

  &.visible {
    opacity: 1;
    transition: opacity 0.1s;
  }

  &.step-0 {
    opacity: 0;
  }

  &.step-2 {
    height: 30px;

    @media #{$mobile} {
      height: 25px;
    }
  }

  .revenue-info {
    display: flex;
    width: 100%;

    @media #{$desktop} {
      flex-direction: column;
    }

    @media #{$mobile} {
      align-items: flex-start;
    }

    .revenue-info-label {
      @include avenir-heavy;
      align-items: center;
      color: $grey;
      display: flex;
      font-size: 12px;
      justify-content: center;
      padding: 3px;
      text-align: center;
      width: 100%;

      @media #{$desktop} {
        height: 35px;
      }

      @media #{$mobile} {
        align-items: flex-start;
        justify-content: flex-start;
        text-align: left;
        width: 50%;
      }
    }

    .revenue-info-value {
      @include avenir-roman;
      color: $dibs-darker-grey;
      font-size: 14px;
      text-align: center;
      width: 100%;

      @media #{$desktop} {
        height: 20px;
      }

      @media #{$mobile} {
        text-align: right;
        width: 50%;
      }
    }
  }

  .total-revenue-container {
    align-items: center;
    background: $white;
    display: flex;
    left: 30px;
    margin: 0;
    position: absolute;

    @media #{$mobile} {
      font-size: 18px;
      left: 33px;
    }

    div {
      @include avenir-roman;
      color: $dibs-green;
      font-size: 26px;
      font-weight: 900;
    }

    svg {
      background: $white;
      height: 30px;
      left: -30px;
      margin-right: 10px;
      position: absolute;

      @media #{$mobile} {
        height: 13px;
        left: -13px;
      }

      polygon {
        fill: $dibs-darker-grey;
        stroke: none;
      }
    }
  }
}
