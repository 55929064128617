@import "../../shared/styles/base";

.tutorial-container {
  animation: fade-in linear 0.1s;
  background: fade-out($black, 0.55);
  z-index: 100000000;

  &.hidden {
    display: none;
  }
}
