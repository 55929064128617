@import "./base";

.dibs-loader-container {
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;

  .dibs-loader {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 50%;
    justify-content: center;
    width: 100%;

    &.is-button {
      height: 100%;
    }
  }

  .dot {
    animation: dibs-loader-animation 1000ms ease infinite 0ms;
    border-radius: 50%;
    float: left;
    height: 10px;
    margin: 0 5px;
    transform: scale(0);
    width: 10px;

    &:nth-child(2) {
      animation: dibs-loader-animation 1000ms ease infinite 300ms;
    }

    &:nth-child(3) {
      animation: dibs-loader-animation 1000ms ease infinite 600ms;
    }
  }

  .blue-dot {
    border: 2px solid $dibs-blue;
  }

  .white-dot {
    border: 2px solid $white;
  }
}
