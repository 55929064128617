@import "_base.scss";

.retail-list-card {
  .retail-list-card-title {
    @include avenir-heavy();
  }
  .retail-list--card-section {
    margin: 5px 0;
  }

  .retail-list-card-buttons {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
}
