@import "../../../../../shared/styles/base";

.react-autosuggest__container {
  position: relative;

  &.react-autosuggest__container--open {
    background: white;
    z-index: 100;
    width: inherit;
  }

  .react-autosuggest__suggestions-container--open {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.075);
    position: absolute;
    right: 0px;
    top: 22px;
    overflow: auto;
  }
}

.react-autosuggest__section-title {
  margin-bottom: 2px;
  margin-top: 10px;
  padding: 0 10px;
}

input.react-autosuggest__input.react-autosuggest__input--open {
  border-bottom: 1px solid $rose-gold;
  width: 200px;
}

.autosuggest-custom-icon {
  margin-top: 3px;
  float: right;
}

.autosuggest-email-line {
  color: fade-out($black, 0.5);
  font-style: italic;
  word-wrap: break-word;
}

.react-autosuggest__suggestions-list {
  padding: 0;
  margin: 0;
}

.react-autosuggest__suggestions-container {
  width: inherit;
}

.react-autosuggest__section-container {
  background: white;
  border: 1px solid lighten($dashboard-grey, 5%);
  border-top: 0;
  box-shadow: 4px 5px 17px -10px $studio-box-shadow;
  margin: 0;
  overflow: hidden;
  padding: 0;

  &.react-autosuggest__section-container--first {
    border-bottom: 0;
    margin-top: 2px;
  }
}

.react-autosuggest__suggestion {
  cursor: pointer;
  list-style-type: none;
  padding: 3px 10px 5px;
  overflow: hidden;
}

.react-autosuggest__suggestion--highlighted {
  background-color: rgba(66, 138, 255, 0.4);
}
