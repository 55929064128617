@import "../../shared/styles/base";

.profile-picture-wrapper {
  @include hide-on-mobile;
  display: flex;
  justify-content: center;
  min-height: 70px;
  width: 70px;

  input {
    display: none;
  }
}

.profile-picture-container {
  border-radius: 50%;
  height: 70px;
  overflow: hidden;
  width: 70px;

  img {
    border-radius: 50%;
    height: 70px;
    width: 70px;

    &.is-updating {
      filter: grayscale(30%) opacity(75%);
    }
  }
}
