@import "./base";

.search-bar-container {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;

  @media #{$mobile} {
    flex-direction: column;
  }

  button:not(.clear-button) {
    margin-left: 10px;

    @media #{$mobile} {
      margin: {
        left: 0;
        top: 27px;
      }
    }
  }

  .clear-button {
    background: $white;
    color: $black;
    height: 100%;
    position: absolute;
    right: 3px;
    z-index: 100;

    svg {
      height: 8px;
      width: 8px;
    }
  }
}

.clear {
  stroke: $black;
}

.search-bar {
  position: relative;
  align-items: flex-start;
  border-bottom: solid 1px $rose-main;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 280px;

  @media #{$mobile} {
    margin-left: 0px;
    width: 252px;
  }

  &.is-focused {
    border-bottom: solid 2px $rose-main;
  }

  > svg {
    height: 15px;
    min-width: 15px;

    @media (min-width: 350px) {
      margin-right: 6px;
    }

    path {
      fill: $input-grey;
    }
  }

  input {
    // sass-lint:disable-block no-important
    border: 0 !important;
    margin: 0 !important;
    max-width: 250px;
    width: 250px;

    @media #{$mobile} {
      max-width: 210px;
    }
  }
}
