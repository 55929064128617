@import "../../shared/styles/base";

.error-bar {
  background: $dibs-error-red;
}
.success-bar {
  background: $dibs-success-green;
}
.banner {
  // align-items: flex-start;
  display: flex;
  min-height: 40px;
  padding: 0px 0;
  margin-left: 0;
  pointer-events: none;
  position: fixed;
  width: 100%;
  z-index: 100000000;

  * {
    text-align: center;
  }

  &.error {
    background: $dibs-error-red;
  }
  &.success {
    background: $dibs-success-green;
  }

  &.notice {
    background: $dibs-green;
  }

  &.hidden {
    display: none;
  }

  &.login-error-bar {
    .message {
      padding-left: $error-bar-button-width;
      width: unquote("calc(100% - #{2 * $error-bar-button-width})");
    }
  }

  .message {
    @include avenir-roman;
    align-items: center;
    color: $white;
    display: flex;
    font-size: 14px;
    height: 100%;
    justify-content: center;
    padding-left: $error-bar-button-width;
    width: unquote("calc(100% - #{2 * $error-bar-button-width})");

    @media #{$mobile} {
      width: unquote("calc(100% - #{$error-bar-button-width})");
    }

    div {
      max-width: 550px;

      @media #{$mobile} {
        max-width: 260px;
      }
    }
  }

  .clear-btn {
    align-items: flex-end;
    margin-right: 0;
    display: flex;
    pointer-events: all;
    width: $error-bar-button-width;
    background: "transparent";

    svg {
      path {
        stroke: $white;
        stroke-width: 2px;
      }
    }
  }
}
