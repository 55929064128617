@import "../../shared/styles/base";

.nav-link {
  padding: {
    bottom: 5px;
    left: 35px;
    top: 5px;
  }

  @media #{$mobile} {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .nav-link-icon {
    height: 25px;
    stroke: $dibs-darker-grey;

    @media #{$desktop} {
      display: none;
    }
  }

  .dashboard-icon {
    fill: $dibs-darker-grey;
  }

  .nav-link-label {
    @include avenir-roman;
    color: $white;
    display: inline;
    font-size: 14px;

    @media #{$mobile} {
      @include avenir;
      color: $dibs-darker-grey;
      font-size: 9px;
    }
  }
}

.nav-link-active {
  border-left: 5px solid $black;
  padding-left: 30px;

  .nav-link-label {
    @media #{$desktop} {
      @include avenir-heavy;
    }
  }

  @media #{$mobile} {
    border: 0;
    padding: 0;

    .nav-link-icon {
      fill: $rose-gold;
      stroke: $rose-gold;
    }

    .dashboard-icon {
      fill: $rose-gold;
    }

    .nav-link-label {
      color: $rose-gold;
    }
  }
}
