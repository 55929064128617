@import "../../../../shared/styles/base";

.layout-right-button__container {
  @include hide-on-desktop;
  font-size: 18px;
  position: fixed;
  right: 10px;
  top: 10px;
  z-index: 5000;
}
