@import "./vars";
@import "../../../node_modules/bootstrap/scss/mixins";

@mixin avenir {
  font-family: "Roboto";
}

@mixin avenir-book {
  font-family: "Roboto";
}

@mixin avenir-roman {
  font-family: "Roboto";
}

@mixin avenir-heavy {
  font-family: "Roboto";
}

@mixin playfair {
  font-family: "Playfair Display";
}

@mixin raleway {
  font-family: "rawline"; // is raleway but with number styles fixed
}

@mixin calc($property, $str) {
  #{$property}: unquote("calc(#{$str})");
}

@mixin center-child {
  align-items: center;
  display: flex;
  justify-content: center;
}

@mixin center-horizontally-in-parent {
  left: 0;
  margin: 0 auto;
  right: 0;
}

@mixin center-vertically-in-view {
  position: relative;
  top: 50vh;
  transform: translateY(-50%);
}

@mixin dashed-bottom {
  border-bottom: $rose-gold dashed 1px;
  text-decoration: none;

  &:active,
  &:hover {
    text-decoration: none;
  }
}

@mixin hide-on-mobile {
  @media #{$mobile} {
    display: none;
  }
}

@mixin hide-on-tablet {
  @media #{$tablet} {
    display: none;
  }
}

@mixin hide-on-desktop {
  @media #{$desktop} {
    display: none;
  }
}

@mixin momentum-scroll {
  @media #{$mobile} {
    // sass-lint:disable-block no-vendor-prefixes
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;

    > div {
      transform: translate3d(0, 0, 0);
    }
  }
}

@mixin no-text-decoration {
  text-decoration: none;

  &:active,
  &:hover,
  &:visited,
  &:focus {
    text-decoration: none;
  }
}

//======================================================
//
// CSSTransitionGroup CSS rules
//
//======================================================

@mixin slide-up {
  &.slide-up-enter {
    position: relative;
    transform: translateY(100%);

    &.slide-up-enter-active {
      transform: translateY(0%);
      transition: transform 1000ms ease-in-out;
    }
  }

  &.slide-up-leave {
    transform: translateY(-100%);

    &.slide-up-leave-active {
      transform: translateY(0%);
      transition: transform 1000ms ease-in-out;
    }
  }

  &.slide-up-appear {
    position: relative;
    transform: translateY(100%);
    z-index: 2;

    &.slide-up-appear-active {
      transform: translateY(0%);
      transition: transform 1000ms ease-in-out;
    }
  }
}

@mixin slide-left {
  &.slide-up-enter {
    transform: translateX(100%);

    &.slide-up-enter-active {
      transform: translateX(0%);
      transition: transform 1000ms ease-in-out;
    }
  }

  &.slide-up-leave {
    transform: translateX(-100%);

    &.slide-up-leave-active {
      transform: translateX(0%);
      transition: transform 1000ms ease-in-out;
    }
  }

  &.slide-up-appear {
    transform: translateX(100%);

    &.slide-up-appear-active {
      transform: translateX(0%);
      transition: transform 1000ms ease-in-out;
    }
  }
}

//======================================================
//
// Fluid Typography
// Source: https://css-tricks.com/snippets/css/fluid-typography/
//
//======================================================

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-fontsize($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(
          #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}
